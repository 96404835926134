<template>
  <div>
    <v-navigation-drawer
      :value="isDrawerOpen"
      app
      floating
      width="420"
      class="app-navigation-menu"
      :right="$vuetify.rtl"
      @input="val => $emit('update:is-drawer-open', val)"
    >
      <!-- Navigation Header -->
      <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-img
            :src="require('@/assets/images/logos/main-logo.png')"
            max-height="60px"
            max-width="60px"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
          <v-slide-x-transition>
            <h4 class="text--primary">GOODLIFE BUILLDINGS</h4>
          </v-slide-x-transition>
        </router-link>
      </div>

      <!-- Navigation Items -->
      <v-list expand shaped class="vertical-nav-menu-items">
        <nav-menu-link title="DASHBOARD" :to="{ name: 'dashboard' }"
                       :icon="icons.mdiHomeOutline"></nav-menu-link>
        <nav-menu-link v-if="new_user" title="NEW USER" :to="{ name: 'new-user' }"
                       :icon="icons.mdiHomeOutline"></nav-menu-link>
        <nav-menu-group v-if="register" title="REGISTER INTERFACE"
                        :icon="icons.mdiPencilPlus">
          <nav-menu-link class="ml-3" title="Lessee"
                         :to="{ name: 'register-lessee' }"></nav-menu-link>
          <nav-menu-link class="ml-3" title="Branch"
                         :to="{ name: 'register-branch' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-group v-if="report_interface" title="REPORT INTERFACE"
                        :icon="icons.mdiFormatListBulleted">
          <nav-menu-link class="ml-3" title="List of Lessee"
                         :to="{ name: 'list-of-lessee' }"></nav-menu-link>
          <nav-menu-link class="ml-3" title="List of Branches"
                         :to="{ name: 'list-of-branches' }"></nav-menu-link>
          <nav-menu-link class="ml-3" title="List of Security Deposits"
                         :to="{ name: 'list-of-security-deposits' }"></nav-menu-link>
          <nav-menu-link class="ml-3" title="Billing Report"
                         :to="{ name: 'billing-report' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link v-if="search_interface" title="SEARCH INTERFACE"
                       :to="{ name: 'search-info' }" :icon="icons.mdiSearchWeb"></nav-menu-link>
        <nav-menu-link  v-if="payment_interface" title="PAYMENT INTERFACE"
                       :to="{ name: 'payment' }" :icon="icons.mdiCash100"></nav-menu-link>
        <nav-menu-link  v-if="remittances_interface" title="REMITTANCES INTERFACE"
                       :to="{ name: 'remittances' }" :icon="icons.mdiCash100"></nav-menu-link>
        <nav-menu-link  v-if="bank_tally" title="BANK TALLY"
                       :to="{ name: 'bank-tally' }" :icon="icons.mdiBank"></nav-menu-link>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {
    mdiHomeOutline,
    mdiPencilPlus,
    mdiFormatListBulleted,
    mdiCash100,
    mdiSearchWeb,
    mdiBank,
  } from '@mdi/js'
  import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
  import NavMenuGroup from './components/NavMenuGroup.vue'
  import NavMenuLink from './components/NavMenuLink.vue'
  import {mapActions, mapGetters} from 'vuex'

  export default {
    components: {
      NavMenuSectionTitle,
      NavMenuGroup,
      NavMenuLink,
    },
    computed: {
      ...mapGetters('authentication', ['position']),
      ...mapGetters('authentication', [
        'register',
        'report_interface',
        'new_user',
        'search_interface',
        'payment_interface',
        'remittances_interface',
        'bank_tally',
      ]),
    },
    props: {
      isDrawerOpen: {
        type: Boolean,
        default: null,
      },
    },
    setup() {
      return {
        icons: {
          mdiCash100,
          mdiSearchWeb,
          mdiHomeOutline,
          mdiPencilPlus,
          mdiFormatListBulleted,
          mdiBank,
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  .app-title {
    font-size: 1.25rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  // ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
  .app-logo {
    transition: all 0.18s ease-in-out;

    .v-navigation-drawer--mini-variant & {
      transform: translateX(-4px);
    }
  }

  @include theme(app-navigation-menu) using($material) {
    background-color: map-deep-get($material, 'background');
  }

  .app-navigation-menu {
    .v-list-item {
      &.vertical-nav-menu-link {
        ::v-deep .v-list-item__icon {
          .v-icon {
            transition: none !important;
          }
        }
      }
    }
  }

  // You can remove below style
  // Upgrade Banner
  .app-navigation-menu {
    .upgrade-banner {
      position: absolute;
      bottom: 13px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
