<template>
  <div>
    <v-menu offset-y left nudge-bottom="14" min-width="230"
            content-class="user-profile-menu-content">
      <template v-slot:activator="{ on, attrs }">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" v-bind="attrs" v-on="on">
            <v-img :src="user_picture"></v-img>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
            <v-avatar size="40px">
              <v-img :src="user_picture"></v-img>
            </v-avatar>
          </v-badge>
          <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
            <span class="text--primary font-weight-semibold mb-n1">
              {{ name }}
            </span>
            <small class="text--disabled text-capitalize">{{ position }}</small>
          </div>
        </div>
        <v-divider class="my-2"></v-divider>
        <v-list-item link @click="is_change_password = true">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCogOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item link @click="logout">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!--    dialog here-->
    <v-dialog v-model="show" persistent width="50%" dark>
      <v-card color="primary">
        <v-card-text>
          {{ txt }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_change_password" persistent max-width="50%">
      <ChangePassword></ChangePassword>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCogOutline,
    mdiLogoutVariant,
  } from '@mdi/js'
  import {mapActions, mapGetters} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import ChangePassword from '@/views/dashboard/ChangePassword'

  export default {
    data() {
      return {
        alert: false,
        alert_message: '',
        txt:'',

        show: false,
        user_picture: require('@/assets/images/avatars/1.png'),
        avatarImg: require('@/assets/images/misc/upload_cheque.jpg'),
        is_change_password: false,

        icons: {
          mdiCogOutline,
          mdiLogoutVariant,
        },
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['id_no', 'name', 'position',]),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    components: {
      snackBarDialog,
      ChangePassword,
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('authentication', ['logout_credentials',]),
      logout() {
        this.show = true
        this.txt = 'LOGGING OUT.....'

        const data = new FormData()
        data.append('id_no', this.id_no)
        this.logout_credentials(data)
          .then(() => {
            this.$router.push({path: '/'})
            this.show = false
            this.txt = ''
          })
          .catch(error => {
            console.log(error)
            this.show = false
            this.txt = ''
          })
      },
    },
  }
</script>

<style lang="scss">
  .user-profile-menu-content {
    .v-list-item {
      min-height: 2.5rem !important;
    }
  }
</style>
