import axios from 'axios'
import store from "@/store";

axios.defaults.withCredentials = true

const state = {
  authenticated: false,
  need_change_password: false,
  user_id: 0,
  id_no: 'CHAP-0-0000',
  name: 'NA',
  position: 'NA',

  register: false,
  report_interface: false,
  new_user: false,
  search_interface: false,
  payment_interface: false,
  remittances_interface: false,
  bank_tally: false,
}

const mutations = {
  set_authenticated(state, authenticated) {
    state.authenticated = authenticated
  },
  set_need_change_password(state, need_change_password) {
    state.need_change_password = need_change_password
  },
  set_user_id(state, user_id) {
    state.user_id = user_id
  },
  set_id_no(state, id_no) {
    state.id_no = id_no
  },
  set_name(state, name) {
    state.name = name
  },
  set_position(state, position) {
    state.position = position
  },
  set_register(state, register) {
    state.register = register
  },
  set_report_interface(state, report_interface) {
    state.report_interface = report_interface
  },
  set_new_user(state, new_user) {
    state.new_user = new_user
  },
  set_search_interface(state, search_interface) {
    state.search_interface = search_interface
  },
  set_payment_interface(state, payment_interface) {
    state.payment_interface = payment_interface
  },
  set_remittances_interface(state, remittances_interface) {
    state.remittances_interface = remittances_interface
  },
  set_bank_tally(state, bank_tally) {
    state.bank_tally = bank_tally
  },
}

const getters = {
  authenticated: state => state.authenticated,
  need_change_password: state => state.need_change_password,
  user_id: state => state.user_id,
  id_no: state => state.id_no,
  name: state => state.name,
  position: state => state.position,
  register: state => state.register,
  report_interface: state => state.report_interface,
  new_user: state => state.new_user,
  search_interface: state => state.search_interface,
  payment_interface: state => state.payment_interface,
  remittances_interface: state => state.remittances_interface,
  bank_tally: state => state.bank_tally,
}
const actions = {
  login_credentials({commit, rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios
        .get('/sanctum/csrf-cookie')
        .then(() => {
          axios
            .post('api/login-user-profile', data)
            .then(response => {
              if (response.status === 201) {
                commit('set_authenticated', false)
              } else {
                commit('set_authenticated', true)
                commit('set_need_change_password', response.data[0].user.is_need_change_password)
                commit('set_name', response.data[0].user.name)
                commit('set_user_id', response.data[0].user.id)
                commit('set_id_no', response.data[0].user.id_no)
                commit('set_position', response.data[0].user.position)
                store.dispatch(
                  'authentication/pages_striction',
                  {root: true})
              }
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  pages_striction({commit, rootGetters}) {
    var position = rootGetters['authentication/position']
    commit(
      'set_register',(position === 'ADMIN SPECIAL'||position === 'ADMIN')
      ,
    )
    commit(
      'set_report_interface', (position === 'ADMIN SPECIAL'||position === 'ADMIN')
      ,
    )
    commit(
      'set_new_user', position === 'ADMIN SPECIAL'
      ,
    )
    commit(
      'set_search_interface', (position === 'ADMIN SPECIAL'||position === 'ADMIN')
      ,
    )
    commit(
      'set_payment_interface', (position === 'ADMIN SPECIAL'||position === 'ADMIN')
      ,
    )
    commit(
      'set_remittances_interface', (position === 'ADMIN SPECIAL'||position === 'ADMIN')
      ,
    )
    commit(
      'set_bank_tally', (position === 'ADMIN SPECIAL'||position === 'ADMIN')
      ,
    )
  },
  logout_credentials({commit, rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios
        .post('api/logout-user-profile', data)
        .then(response => {
          commit('set_authenticated', false)
          commit('set_need_change_password', false)
          commit('set_name', '')
          commit('set_user_id', '')
          commit('set_id_no', '')
          commit('set_position', '')
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  force_logout_credentials({commit, rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios
        .post('api/force-logout-credentials', data)
        .then(response => {
          commit('set_authenticated', false)
          commit('set_need_change_password', false)
          commit('set_name', '')
          commit('set_user_id', '')
          commit('set_id_no', '')
          commit('set_position', '')
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
