import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'pages/login',
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: 'Login',
      requiresVisitor: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/Error.vue'),
    meta: {
      layout: 'blank',
      title: '404|Not Found',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/register-lessee',
    name: 'register-lessee',
    component: () => import('@/views/register_interface/NewLessee'),
    meta: {
      title: 'New Lessee',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAdminSpecial: true,
    },
  },
  {
    path: '/register-branch',
    name: 'register-branch',
    component: () => import('@/views/register_interface/NewBranch'),
    meta: {
      title: 'New Branch',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAdminSpecial: true,
    },
  },
  {
    path: '/list-of-lessee',
    name: 'list-of-lessee',
    component: () => import('@/views/report_interface/ListOfMember'),
    meta: {
      title: 'List of Lessee',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAdminSpecial: true,
    },
  },
  {
    path: '/list-of-branches',
    name: 'list-of-branches',
    component: () => import('@/views/report_interface/ListOfBranches'),
    meta: {
      title: 'List of Branches',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAdminSpecial: true,
    },
  },
  {
    path: '/list-of-security-deposits',
    name: 'list-of-security-deposits',
    component: () => import('@/views/report_interface/ListOfDeposits'),
    meta: {
      title: 'List of Security Deposits',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAdminSpecial: true,
    },
  },
  {
    path: '/billing-report',
    name: 'billing-report',
    component: () => import('@/views/report_interface/BillingReport'),
    meta: {
      title: 'Billing Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAdminSpecial: true,
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/payment_interface/MainInterface'),
    meta: {
      title: 'Payments',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAdminSpecial: true,
    },
  },
  {
    path: '/remittances',
    name: 'remittances',
    component: () => import('@/views/report_interface/RemittancesReport'),
    meta: {
      title: 'Remittances',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAdminSpecial: true,
    },
  },
  {
    path: '/bank-tally',
    name: 'bank-tally',
    component: () => import('@/views/report_interface/BankTally'),
    meta: {
      title: 'Bank Tally',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAdminSpecial: true,
    },
  },
  {
    path: '/search-info',
    name: 'search-info',
    component: () => import('@/views/search_interface/SearchMembers'),
    meta: {
      title: 'Search Members',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAdminSpecial: true,
    },
  },
  {
    path: '/new-user',
    name: 'new-user',
    component: () => import('@/views/admin_interface/NewUser'),
    meta: {
      title: 'New User',
      requiresAuth: true,
      requiresAdminSpecial: true,
    },
  },
]

const router = new VueRouter({
  // mode: "history",
  routes,
})

export default router
